import cloneDeep from 'lodash/cloneDeep'
import pick from 'lodash/pick'
import { ref, unref, useContext, useRouter, useStore } from '@nuxtjs/composition-api'

// The following `userFields` will be synced between client-side and server-side
export const userFields = [
  { craft: 'email', nuxt: 'email', type: 'String!' },
  { craft: 'userCity', nuxt: 'city', type: 'String' },
  { craft: 'userFirstName', nuxt: 'firstName', type: 'String!' },
  { craft: 'userGender', nuxt: 'gender', type: 'String' },
  { craft: 'userHasMarketingConsent', nuxt: 'hasMarketingConsent', type: 'Boolean' },
  { craft: 'userHasMembership', nuxt: 'hasMembership', type: 'Boolean' },
  { craft: 'userHasNewsletter', nuxt: 'hasNewsletter', type: 'Boolean' },
  { craft: 'userHasSearchUpdates', nuxt: 'hasSearchUpdates', type: 'Boolean' },
  { craft: 'userHouseNumber', nuxt: 'houseNumber', type: 'String' },
  { craft: 'userInsertion', nuxt: 'insertion', type: 'String' },
  { craft: 'userLastName', nuxt: 'lastName', type: 'String!' },
  { craft: 'userStreet', nuxt: 'street', type: 'String' },
  { craft: 'userSuspended', nuxt: 'suspended', type: 'Boolean' },
  { craft: 'userTelephone', nuxt: 'telephone', type: 'String' },
  { craft: 'userZipCode', nuxt: 'zipCode', type: 'String' },
]

export function setupRequireUser() {
  const { localePath } = useContext()
  const store = useStore()
  const router = useRouter()
  const hasUser = ref(store.getters['user/hasUser'])

  // When not logged in, redirect to login page
  if (!hasUser.value) {
    router.push(localePath({ name: 'account-login' }))
  }

  return { hasUser }
}

export function setupRequireNoUser() {
  const { localePath } = useContext()
  const store = useStore()
  const router = useRouter()
  const hasUser = ref(store.getters['user/hasUser'])

  // When logged in, redirect to account page
  if (hasUser.value) {
    router.push(localePath({ name: 'account-detail' }))
  }

  return { hasUser }
}

export const setupUser = () => {
  const store = useStore()
  const router = useRouter()
  const { $graphqlFetch } = useContext()

  /**
   * Based on the new and old user values, we will set the userNewsletterDate field.
   *
   * Note that the date should be set the moment the user chooses 'yes I want a newsletter'
   */
  const getNewsletterDate = (newUser) => {
    const oldUser = store.getters['user/getUser'] || { userHasNewsletter: false }
    if (unref(newUser).hasNewsletter) {
      if (unref(oldUser).hasNewsletter) {
        // Keep the original date
        return undefined
      } else {
        // Set the date to now
        return new Date().toISOString()
      }
    } else {
      // There should not be a date
      return null
    }
  }
  /**
   * Based on the new and old user values, we will set the userMarketingConsentDate field.
   *
   * Note that the date should be set the moment the user chooses 'yes I want to be added to the marketing list'
   */
  const getMarketingConsentDate = (newUser) => {
    const oldUser = store.getters['user/getUser'] || { userHasMarketingConsent: false }
    if (unref(newUser).hasMarketingConsent) {
      if (unref(oldUser).hasMarketingConsent) {
        // Keep the original date
        return undefined
      } else {
        // Set the date to now
        return new Date().toISOString()
      }
    } else {
      // There should not be a date
      return null
    }
  }

  const amendDataToFormulate = (user) => {
    const amended = cloneDeep(unref(user))

    // hasMembership is a boolean in Craft but a string in Nuxt
    if (typeof amended.hasMembership === 'boolean') {
      amended.hasMembership = amended.hasMembership ? 'yes' : 'no'
    }

    // insertion is not required but may not be null or undefined
    amended.insertion = amended.insertion || ''

    return amended
  }

  const amendFormulateToData = (user) => {
    const amended = cloneDeep(unref(user))

    // hasMembership is a boolean in Craft but a string in Nuxt
    if (typeof amended.hasMembership === 'string') {
      amended.hasMembership = /^yes|true|1$/i.test(amended.hasMembership)
    }

    return amended
  }

  /**
   * Refresh the token if it is expired
   */
  const checkToken = async () => {
    // Success when the token is still valid
    if (store.getters['jwt/checkTokenIsValid']()) {
      return true
    }

    // Fail when the refresh token is no longer valid
    if (!store.getters['jwt/checkRefreshTokenIsValid']()) {
      logout()
      return false
    }

    // https://graphql-authentication.jamesedmonston.co.uk/usage/authentication#refresh-jwt
    let response
    try {
      response = await $graphqlFetch({
        token: 'user',
        query: `
        mutation refreshToken($refreshToken: String!) {
          refreshToken(refreshToken: $refreshToken) {
            token: jwt
            tokenExpiresAt: jwtExpiresAt
            refreshToken
            refreshTokenExpiresAt
          }
        }
      `,
        variables: { refreshToken: store.getters['jwt/refreshToken'] },
      })
    } catch {
      logout()
      return false
    }

    const token = response?.refreshToken?.token
    const tokenExpiresAt = response?.refreshToken?.tokenExpiresAt
    const refreshToken = response?.refreshToken?.refreshToken
    const refreshTokenExpiresAt = response?.refreshToken?.refreshTokenExpiresAt
    if (!(token && tokenExpiresAt && refreshToken && refreshTokenExpiresAt)) {
      logout()
      return false
    }

    store.commit('jwt/setTokens', { token, tokenExpiresAt, refreshToken, refreshTokenExpiresAt })
    return true
  }

  const login = async (credentials) => {
    let response
    try {
      // https://graphql-authentication.jamesedmonston.co.uk/usage/authentication#log-in
      response = await $graphqlFetch({
        token: 'user',
        query: `
        mutation authenticate($email: String!, $password: String!) {
          authenticate(email: $email, password: $password) {
            token: jwt
            tokenExpiresAt: jwtExpiresAt
            refreshToken
            refreshTokenExpiresAt
            user {
              ... on User {
                id
                searchProfiles: userSearchProfiles
                wishList: userWishList
                ${userFields.map(({ craft, nuxt }) => (nuxt === craft ? craft : `${nuxt}: ${craft}`)).join('\n')}
              }
            }
          }
        }
      `,
        variables: unref(credentials),
      })
    } catch {
      return false
    }

    const token = response?.authenticate?.token
    const tokenExpiresAt = response?.authenticate?.tokenExpiresAt
    const refreshToken = response?.authenticate?.refreshToken
    const refreshTokenExpiresAt = response?.authenticate?.refreshTokenExpiresAt
    const user = response?.authenticate?.user
    if (!(token && tokenExpiresAt && refreshToken && refreshTokenExpiresAt && user)) {
      return false
    }

    store.commit('jwt/setTokens', { token, tokenExpiresAt, refreshToken, refreshTokenExpiresAt })
    // Remove the searchProfiles and wishList from the user, these are part of their own store
    store.commit('user/updateUser', { ...user, searchProfiles: undefined, wishList: undefined })
    // Merge the profiles into the current store.  Note that the store may choose to ignore the merge
    store.commit('searchProfiles/mergeProfiles', JSON.parse(user.searchProfiles || '[]'))
    store.commit('wishList/mergeFavorites', JSON.parse(user.wishList || '[]'))

    // Redirect to the account page
    setTimeout(() => router.push({ path: '/account' }), 25)

    return true
  }

  const logout = (redirectPath = '/') => {
    // Return success when we are already logged out
    if (!store.getters['jwt/token']) {
      return true
    }

    // First reset the token, this will ensure that resetting the user info does not get committed to CraftCMS
    store.commit('jwt/reset')
    // Reset everything related to the user
    store.commit('user/reset')
    store.commit('wishList/reset')
    store.commit('searchProfiles/reset')

    // Redirect to the homepage
    if (redirectPath) {
      setTimeout(() => router.push(redirectPath), 25)
    }

    return true
  }

  const register = async (user) => {
    // https://graphql-authentication.jamesedmonston.co.uk/usage/authentication#register
    try {
      await $graphqlFetch({
        token: 'user',
        query: `
        mutation register($password: String!, $newsletterDate: DateTime, $marketingConsentDate: DateTime, ${userFields.map(({ nuxt, type }) => `$${nuxt}: ${type}`).join(', ')}) {
          register(password: $password, userNewsletterDate: $newsletterDate, userMarketingConsentDate: $marketingConsentDate, ${userFields
            .map(({ craft, nuxt }) => `${craft}: $${nuxt}`)
            .join(', ')}) {
            token: jwt
          }
        }
      `,
        variables: {
          ...unref(user),
          newsletterDate: getNewsletterDate(user),
          marketingConsentDate: getMarketingConsentDate(user),
        },
      })
    } catch (error) {
      return { success: false, messages: [error.toString()] }
    }

    // --- Note that we can not login because the user has not been authenticated yet,
    // --- the user must first click on the activation link in the email send by CraftCMS.
    // store.commit('jwt/setTokens', { token, refreshToken })
    // store.commit('user/updateUser', unref(user))
    // Redirect to the account page
    // setTimeout(() => router.push({ path: '/account' }), 25)

    return { success: true, messages: [] }
  }

  const reset = async (model) => {
    try {
      // https://graphql-authentication.jamesedmonston.co.uk/usage/authentication#forgotten-password
      await $graphqlFetch({
        token: 'user',
        query: `
        mutation forgottenPassword($email: String!) {
          forgottenPassword(email: $email)
        }
      `,
        variables: unref(model),
      })
    } catch {
      return false
    }
    return true
  }

  const activate = async ({ code, id }) => {
    try {
      // https://graphql-authentication.jamesedmonston.co.uk/usage/authentication#activate-user
      await $graphqlFetch({
        token: 'user',
        query: `
        mutation activateUser($code: String!, $id: String!) {
          activateUser(code: $code, id: $id)
        }
      `,
        variables: { code, id },
      })
    } catch {
      return false
    }
    return true
  }

  const resendActivationEmail = async ({ email }) => {
    try {
      // https://graphql-authentication.jamesedmonston.co.uk/usage/authentication#resend-activation-email
      await $graphqlFetch({
        token: 'user',
        query: `
        mutation resendActivation($email: String!) {
          resendActivation(email: $email)
        }
      `,
        variables: { email },
      })
    } catch {
      return false
    }
    return true
  }

  const setPassword = async ({ code, id, password }) => {
    try {
      // https://graphql-authentication.jamesedmonston.co.uk/usage/authentication#set-password
      await $graphqlFetch({
        token: 'user',
        query: `
        mutation setPassword($password: String!, $code: String!, $id: String!) {
          setPassword(password: $password, code: $code, id: $id)
        }
      `,
        variables: { code, id, password },
      })
    } catch {
      return false
    }
    return true
  }

  const suspend = async () => {
    if (!(await checkToken())) {
      return false
    }

    try {
      await $graphqlFetch({
        headers: { Authorization: `JWT ${store.getters['jwt/token']}` },
        token: 'user',
        query: `
          mutation updateViewer {
            user: updateViewer(userSuspended: true) {
              uid
            }
          }
        `,
      })
    } catch {
      // For some reason this call always results in exception 'Invalid Authorization Header'
      // However, we do know that the user has been properly removed.  So we ignore this error.
    }
    return logout('/verwijderd')
  }

  const update = async (user) => {
    if (!(await checkToken())) {
      return false
    }

    // https://graphql-authentication.jamesedmonston.co.uk/usage/user-details#update-viewer
    try {
      await $graphqlFetch({
        headers: { Authorization: `JWT ${store.getters['jwt/token']}` },
        token: 'user',
        query: `
            mutation updateViewer($newsletterDate: DateTime, $marketingConsentDate: DateTime, ${userFields.map(({ nuxt, type }) => `$${nuxt}: ${type}`).join(', ')}) {
              user: updateViewer(userNewsletterDate: $newsletterDate, userMarketingConsentDate: $marketingConsentDate, ${userFields
                .map(({ craft, nuxt }) => `${craft}: $${nuxt}`)
                .join(', ')}) {
                uid
              }
            }
          `,
        variables: {
          ...pick(
            unref(user),
            userFields.map(({ nuxt }) => nuxt)
          ),
          newsletterDate: getNewsletterDate(user),
          marketingConsentDate: getMarketingConsentDate(user),
        },
      })
    } catch {
      return false
    }

    store.commit('user/updateUser', unref(user))
    return true
  }

  return {
    activate,
    amendDataToFormulate,
    amendFormulateToData,
    checkToken,
    login,
    logout,
    register,
    resendActivationEmail,
    reset,
    setPassword,
    suspend,
    update,
  }
}
