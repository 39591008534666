import { set } from 'vue'

export const state = () => ({
  data: {},
  hits: 0,
  misses: 0,
})

export const mutations = {
  hit(state, { key }) {
    // if (this.app.context.isDev) {
    //   // eslint-disable-next-line no-console
    //   console.debug(`%ccache/hit ${key}`, 'border-left: 2px solid lightgray; padding-left: 2px')
    // }
    state.hits += 1
  },
  loading(state, { key, promise }) {
    state.misses += 1
    set(state.data, key, { state: 'loading', expire: new Date().getTime() + 300 * 1000, promise, value: undefined })
  },
  refreshing(state, { key, promise }) {
    state.misses += 1
    set(state.data, key, { state: 'refreshing', expire: new Date().getTime() + 300 * 1000, promise, value: state.data[key]?.value })
  },
  success(state, { key, ttl, value }) {
    set(state.data, key, { state: 'success', expire: new Date().getTime() + (ttl || 300) * 1000, promise: undefined, value })
  },
  error(state, { key, ttl, value }) {
    set(state.data, key, { state: 'error', expire: new Date().getTime() + (ttl || 150) * 1000, promise: undefined, value: value || state.data[key]?.value })
  },
}

export const getters = {
  hits: (state) => state.hits,
  misses: (state) => state.misses,
  expire: (state) => (key) => state.data[key]?.expire,
  state: (state) => (key) => state.data[key]?.state || 'unavailable',
  promise: (state) => (key) => state.data[key]?.promise,
  get: (state) => (key) => state.data[key]?.value,
}

export const actions = {
  /**
   * Check if data is still valid and populate using fetcher when not
   *
   * `key` must be a string that uniquely identifies this data
   * `fetcher` must be an async function that resolves into the data
   * `ttl` is an optional number that specifies the seconds before the data becomes stale
   */
  fetch: async ({ commit, getters }, { key, ttl, fetcher, fallback }) => {
    let promise

    switch (getters.state(key)) {
      case 'loading':
      case 'refreshing':
        promise = getters.promise(key)
        break

      case 'unavailable':
        promise = fetcher()
        commit('loading', { key, promise })
        break

      case 'success':
      case 'error':
        if (new Date().getTime() > getters.expire(key)) {
          promise = fetcher()
          commit('refreshing', { key, promise })
        }
        break
    }

    if (promise) {
      try {
        commit('success', { key, ttl, value: await promise })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error during "cache/fetch"', error)
        commit('error', { key, ttl: ttl / 2, value: fallback })
      }
    }

    return getters.get(key)
  },
}
