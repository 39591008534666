
import { computed, defineComponent, ref, useRoute, useStore } from '@nuxtjs/composition-api'
import { getHomeUrl, setupSiteConfig } from '~/assets/craft'
import { setupTranslationPrefixer } from '~/assets/util'

export default defineComponent({
  setup() {
    const store = useStore()
    const { domain } = setupSiteConfig()
    const route = useRoute()
    const { url: homeUrl } = getHomeUrl(route.value)

    const currentMenuId = ref(null)

    return {
      ...setupTranslationPrefixer('LidMenu', domain),
      currentMenuId,
      homeUrl,
      domain,
      main: computed(() => store.getters['navigation/main']),
      service: computed(() => store.getters['navigation/service']),
      cta: computed(() => store.getters['navigation/cta']),
    }
  },
})
