import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import { stripTags } from '~/assets/util'

export default defineNuxtPlugin((context) => {
  if (context.store.getters['control-panel/isEnabled']('translations')) {
    context.app.i18n.postTranslation = (translation, key) => {
      // Use different color when the key is not translated
      const color = translation === key ? '#660000' : '#006633'
      // eslint-disable-next-line no-console
      console.debug(
        `%c${stripTags(translation)}%c\n${key}`,
        `border-left: 2px solid ${color}; color: ${color}; padding-left: 5px; font-family: Arial`,
        `border-left: 2px solid ${color}; color: ${color}; padding-left: 5px`
      )
      // Wrap the translation with `{` and `}`
      // Note that we first split on `|` to also properly wrap cases such as 'none | one | {n} items'
      return translation
        .split('|')
        .map((part) => `{${part.trim()}}`)
        .join(' | ')
    }
  }
})
