
import { defineComponent, useStore, watch } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '~/assets/util'
import { useCookiebot } from '~/assets/composition/cookiebot'

function setupBodyOverflow() {
  const store = useStore()

  // Add `overflow-hidden` to the body whenever one of the overlays is open
  if (process.client) {
    watch(store.state.overlay, ({ loader, mobileMenu }) => {
      if (loader || mobileMenu) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    })
  }

  // Reset the overlay every time we load a new page
  store.commit('overlay/reset')

  return {}
}

export default defineComponent({
  setup() {
    useCookiebot()

    return {
      ...setupBodyOverflow(),
      ...setupTranslationPrefixer('base'),
    }
  },

  head: {
    link: [
      // Favicon
      { rel: 'shortcut icon', type: 'image/x-icon', href: '/favicons/DB/favicon.ico' },

      // Apple Favicons
      { rel: 'apple-touch-icon', sizes: '180x180', href: '/favicons/DB/apple-touch-icon.png' },
      { rel: 'mask-icon', color: '#dfdf00', href: '/favicons/DB/safari-pinned-tab.svg' },

      // Favicon general
      { rel: 'icon', sizes: '32x32', href: '/favicons/DB/favicon-32x32.png' },
      { rel: 'icon', sizes: '16x16', href: '/favicons/DB/favicon-16x16.png' },
      { rel: 'manifest', href: '/favicons/DB/site.webmanifest' },

      // Favicon Microsoft
      { name: 'msapplication-TileColor', content: '#dfdf00' },
      { name: 'theme-color', content: '#ffffff' },
    ],
  },

  methods: {
    focusOnTargetOnClick(event) {
      const dataFocusTrigger = event.currentTarget.getAttribute('data-focus-trigger')
      const dataLinkTarget = event.currentTarget.getAttribute('data-link-target')
      const target = document.getElementById(dataLinkTarget)
      const isMouseClick = event.type === 'click'
      const isEnterKey = event.type === 'keydown' && event.key === 'Enter'

      if (dataFocusTrigger && target && (isMouseClick || isEnterKey)) {
        target.focus()
      }
    },
  },
})
