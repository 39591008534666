export const state = () => ({
  user: {},
})

export const mutations = {
  reset(state) {
    state.user = {}
  },
  updateUser(state, user) {
    state.user = { ...state.user, ...user }
  },
}

export const getters = {
  getUser: (state) => state.user,
  hasUser: (state) => !!state.user?.id,
}
