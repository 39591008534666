
import { defineComponent, ref, useContext, useFetch, useRoute, useStore } from '@nuxtjs/composition-api'
import { getSiteConfig } from '@/assets/craft'

const statusCodeMap = { 404: 'Sorry, de pagina is niet beschikbaar', 500: 'Er is helaas iets misgegaan' }

export default defineComponent({
  layout: 'error',
  props: {
    error: { type: Object, required: false, default: undefined },
  },
  setup(props) {
    // Set cache control to lower value so browsers will try to revalidate the page sooner, so that if Dierenbescherming
    // has set up a redirect, the redirect will be picked up sooner.
    const { res } = useContext()
    res && res.setHeader('Cache-Control', 'max-age=600, must-revalidate, stale-while-revalidate=60')

    const route = useRoute()
    const store = useStore()

    const title = ref(statusCodeMap[500])
    const domain = ref(null)
    const introduction = ref('')
    const description = ref('')
    const image = ref(undefined)

    useFetch(async () => {
      domain.value = getSiteConfig(route).domain

      const statusCode = props.error?.statusCode || 500
      const page = await store.dispatch('page/fetchError', {
        site: domain.value,
        sourceSection: props.error?.sourceSection || 'default',
        statusCode,
      })

      title.value = page?.title || props.error?.message || statusCodeMap[statusCode] || statusCodeMap[500]
      introduction.value = page?.introduction || ''
      description.value = page?.description || ''
      image.value = page?.image || undefined
    })

    return {
      title,
      domain,
      introduction,
      description,
      image,
    }
  },
})
