
import { defineComponent } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '@/assets/util'

export default defineComponent({
  props: {
    status: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {
      ...setupTranslationPrefixer('lid-footer-social-media'),
    }
  },
})
