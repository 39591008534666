import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=2a602d05"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomLink: require('/home/userapp/components/Atom/Link/Link.vue').default,AtomBrandBlijeKipLogo: require('/home/userapp/components/Atom/Brand/BrandBlijeKipLogo.vue').default,MoleculeFooterSocialMedia: require('/home/userapp/components/Molecule/FooterSocialMedia.vue').default,AtomBrandNplLogo: require('/home/userapp/components/Atom/Brand/BrandNplLogo.vue').default,AtomBrandAnbiLogo: require('/home/userapp/components/Atom/Brand/BrandAnbiLogo.vue').default,AtomBrandCbfLogo: require('/home/userapp/components/Atom/Brand/BrandCbfLogo.vue').default,AtomIconArrowUp: require('/home/userapp/components/Atom/Icon/IconArrowUp.vue').default})
