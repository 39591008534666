import Vue from 'vue'
import Router from 'vue-router'
import { sortRoutes } from '~/assets/util'

Vue.use(Router)

function getSubdomainConfig(hostname) {
  if (/^([ap]-)?ikzoekbaas[.]/i.test(hostname)) return { test: /^\/ikzoekbaas/i, domain: 'ikZoekBaas', site: 'ikZoekBaas', style: 'db' }
  if (/^([ap]-)?mijndieriszoek[.]/i.test(hostname)) return { test: /^\/mijndieriszoek/i, domain: 'mijnDierIsZoek', site: 'mijnDierIsZoek', style: 'db' }
  if (/^((www|a|p)[.])?inspectiedienst[.]/i.test(hostname)) return { test: /^\/inspectiedienst/i, domain: 'inspectiedienst', site: 'inspectiedienst', style: 'lid' }
  return { test: /^\/www/i, domain: 'default', site: 'default', style: 'db' }
}

function applySubdomainsToRoutes(routes, hostname) {
  // `test` is used to test each route path to see if it is used for this call
  // `domain` is the domain value available in the route meta
  // `site` is the site value available in the route meta
  const { test, domain, site, style } = getSubdomainConfig(hostname)

  // Un-split routes where one route contains multiple paths joined using the '\\' character,
  // then filter out non-matching routes and remove the domain prefix
  const activeRoutes = routes
    .flatMap((route) =>
      route.path
        .split('\\')
        .filter((path) => test.test(path))
        .map((path, index) => {
          if (index > 0 && route.name) {
            const [name, locale] = route.name.split('___')
            return { ...route, path, name: `${name}-${index}___${locale}` }
          } else {
            return { ...route, path }
          }
        })
    )
    .map((route) => ({ ...route, path: route.path.replace(test, ''), meta: { ...route.meta, domain, site, style } }))

  // Sort the routes
  sortRoutes(activeRoutes)

  return activeRoutes
}

export function createRouter(ssrContext, createDefaultRouter, routerOptions) {
  const options = routerOptions || createDefaultRouter(ssrContext).options
  return new Router({
    ...options,
    routes: applySubdomainsToRoutes(options.routes, ssrContext ? ssrContext.req.headers.host : location.host),
  })
}
