export const state = () => ({
  loader: false,
  mobileMenu: false,
})

export const mutations = {
  // Reset all overlays to false
  reset: (state) => {
    state.loader = false
    state.mobileMenu = false
  },

  // Either toggle or set the loader overlay to a specific value
  loader: (state, show = undefined) => {
    state.loader = show === undefined ? !state.loader : show
    state.mobileMenu = false
  },

  // Either toggle or set the mobileMenu overlay to a specific value
  mobileMenu: (state, show = undefined) => {
    state.loader = false
    state.mobileMenu = show === undefined ? !state.mobileMenu : show
  },
}

export const getters = {
  loader: (state) => state.loader,
  mobileMenu: (state) => state.mobileMenu,
}
