import { defineNuxtPlugin } from '@nuxtjs/composition-api'

/**
 * Initialize the $gtm module here because at this point we know what the subdomain is (i.e. LID has a different ID)
 */
export default defineNuxtPlugin(({ $config, $gtm, route }) => {
  switch (route.meta?.[0].domain) {
    case 'inspectiedienst':
      $gtm.init($config.lidGoogleTagManagerId)
      break

    default:
      $gtm.init($config.dbGoogleTagManagerId)
      break
  }
})
