import camelCase from 'lodash/camelCase'

const fallback = {
  articleOverview: { title: undefined, url: undefined },
  department: undefined,
  landing: { title: undefined, url: undefined },
  shelterDetail: { title: undefined, url: undefined },
  site: { handle: undefined, language: undefined, name: undefined },
}

export const state = () => fallback

export const getters = {
  articleOverview: (state) => state.articleOverview,
  department: (state) => state.department,
  landing: (state) => state.landing,
  shelterDetail: (state) => state.shelterDetail,
  site: (state) => state.site,
}

export const mutations = {
  set(state, { articleOverview, department, landing, shelterDetail, site }) {
    state.articleOverview = articleOverview
    state.department = department
    state.landing = landing
    state.shelterDetail = shelterDetail
    state.site = site
  },
}

export const actions = {
  async fetchSeo({ dispatch }) {
    const key = `global;seo`
    const ttl = 3600

    const fetcher = async () => {
      const response = await fetch(`${this.$config.cmsSiteUrl}/api/global/seo/default.json`)
      return await response.json()
    }

    return await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
  },

  async fetch({ commit, dispatch }, { site, slug, push }) {
    const site_ = site || camelCase(slug)
    const key = `global;site:${site_}`
    const ttl = 3600

    const fetcher = async () => {
      return await this.$restFetch(`/api/global/site/${site_}.json`)
    }

    const data = await dispatch('cache/fetch', { key, ttl, fetcher, fallback }, { root: true })
    if (push) {
      commit('set', data)
    }
    return data
  },
}
