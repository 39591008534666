function explodeUrl(url, rewriteMap) {
  const { protocol, host, rest } = url.match(/^(?<protocol>[^:]+:)?(\/\/(?<host>[^/]+))?(?<rest>.*$)/i).groups
  const replacedHost = rewriteMap[host] || host || ''
  const type = [undefined, 'http:', 'https:'].includes(protocol) ? 'web' : 'other'
  return { url: [type === 'web' ? (replacedHost ? '//' : '') : protocol, replacedHost, rest].join(''), host: replacedHost, protocol, rest, type }
}

function getDistance(sourceHost, targetHost) {
  if (!sourceHost || !targetHost) {
    return 0
  }

  const sourceParts = sourceHost.split('.').reverse()
  const targetParts = targetHost.split('.').reverse()

  if (sourceParts?.[0] !== targetParts?.[0]) {
    return -1
  }
  if (!(sourceParts?.[0] === 'localhost' || targetParts?.[0] === 'localhost') && sourceParts?.[1] !== targetParts?.[1]) {
    return -1
  }
  if (!(sourceParts?.[0] === 'test' || targetParts?.[0] === 'test') && sourceParts?.[1] !== targetParts?.[1]) {
    return -1
  }

  for (let index = 0; index < sourceParts.length; index++) {
    if (sourceParts[index] !== targetParts[index]) {
      return Math.max(sourceParts.length, targetParts.length) - index
    }
  }
  return 0
}

/**
 * Provides information on a link
 *
 * Example:
 * > linkDetails('//example.com/foo', '//www.example.com/bar', { rewrites:[['www.example.com', 'example.com']] })
 * > --> { type: 'web', scope: 'inside', url: '/bar' }
 */
export function linkDetails(sourceUrl, targetUrl, options) {
  // Convert lists of rewrites into a map
  // [['www.example.com', 'example.com']] -> {'www.example.com': 'www.example.com', 'example.com': 'www.example.com'}
  const rewriteMap = Object.fromEntries((options?.rewrites || []).flatMap((rewrites) => rewrites.map((rewrite) => [rewrite, rewrites[0]])))

  // Explode the url into its parts, and perform possible rewrites
  const source = explodeUrl(sourceUrl || '', rewriteMap)
  const target = explodeUrl(targetUrl || '', rewriteMap)

  // The link `type` determines whether it links to a web page or something else, i.e. an email link
  const type = target.type

  // The link `scope` determines whether it links to `inside` or `outside` the app
  // const scope = type === 'web' && [undefined, source.host].includes(target.host) ? 'inside' : 'outside'
  const scope = type === 'web' && (source.host === '' || ['', source.host].includes(target.host)) ? 'inside' : 'outside'

  // The link `distance` is the distance between source and target host, i.e. example.com and subdomain.example.com have distance `1`
  const distance = scope === 'inside' ? 0 : getDistance(source.host, target.host)

  // The link `url` is a relative url when `inside` the app or otherwise an absolute url
  const url = scope === 'inside' ? target.rest : target.url

  return { distance, scope, type, url }
}
