//
// Note that this storage is configured as sessionStorage, see nuxt-vuex-localstorage in nuxt.config.js
//

export const state = () => ({
  token: null,
  tokenExpiresAt: null,
  refreshToken: null,
  refreshTokenExpiresAt: null,
})

export const mutations = {
  setTokens(state, { token, tokenExpiresAt, refreshToken, refreshTokenExpiresAt }) {
    state.token = token
    state.tokenExpiresAt = tokenExpiresAt
    state.refreshToken = refreshToken
    state.refreshTokenExpiresAt = refreshTokenExpiresAt
  },
  reset(state) {
    if (state.token) state.token = null
    if (state.tokenExpiresAt) state.tokenExpiresAt = null
    if (state.refreshToken) state.refreshToken = null
    if (state.refreshTokenExpiresAt) state.refreshTokenExpiresAt = null
  },
}

export const getters = {
  token: (state) => state.token,
  refreshToken: (state) => state.refreshToken,

  checkTokenIsValid: (state) => () => {
    if (!state.token) {
      return false
    }
    const deadline = new Date(state.tokenExpiresAt)
    // Bring the deadline one minute forward
    deadline.setMinutes(deadline.getMinutes() - 1)
    return new Date() < deadline
  },

  checkRefreshTokenIsValid: (state) => () => {
    if (!state.refreshToken) {
      return false
    }
    const deadline = new Date(state.refreshTokenExpiresAt)
    // Bring the deadline one minute forward
    deadline.setMinutes(deadline.getMinutes() - 1)
    return new Date() < deadline
  },
}
