
import { ref, computed, useRoute, useRouter, useContext, useStore, defineComponent } from '@nuxtjs/composition-api'
import { getHomeUrl, setupSiteConfig } from '~/assets/craft'
import { setupTranslationPrefixer } from '~/assets/util'

function setupSearch() {
  const router = useRouter()
  const { localePath } = useContext()
  const searchInput = ref('')
  const searchHandler = () => {
    router.push(localePath({ path: '/zoeken', query: { search: searchInput.value } }))
  }
  return { searchHandler, searchInput }
}

export default defineComponent({
  setup() {
    const store = useStore()
    const main = computed(() => store.getters['navigation/main'])
    const service = computed(() => store.getters['navigation/service'])
    const secondaryMenuTitle = computed(() => store.getters['navigation/secondaryMenuTitle'])
    const cta = computed(() => store.getters['navigation/cta'])

    const route = useRoute()
    const { domain } = setupSiteConfig()
    const { url: homeUrl, simpleMenu, ikZoekBaas } = getHomeUrl(route.value)
    const layers = ref({
      layer2: {
        currentId: null,
        status: false,
        shouldReverse: false,
      },
      layer3: {
        currentId: null,
        status: false,
        shouldReverse: false,
      },
      layer4: {
        currentId: null,
        status: false,
        shouldReverse: false,
      },
    })
    const addNextChildren = (childrenList, parent, layer) => {
      if (layer === 2) {
        layers.value.layer2.status = true
        layers.value.layer2.currentId = parent
      }

      if (layer === 3) {
        layers.value.layer3.status = true
        layers.value.layer3.currentId = parent
      }

      if (layer === 4) {
        layers.value.layer4.status = true
        layers.value.layer4.currentId = parent
      }
    }
    const reverse = () => {
      let done = false
      let objective

      if (done === false && layers.value.layer4.status === true) {
        objective = 4
        layers.value.layer4.shouldReverse = true
        done = true
      }

      if (done === false && layers.value.layer3.status === true) {
        objective = 3
        layers.value.layer3.shouldReverse = true
        done = true
      }

      if (done === false && layers.value.layer2.status === true) {
        objective = 2
        layers.value.layer2.shouldReverse = true
        done = true
      }

      setTimeout(() => {
        layers.value[`layer${objective}`].status = false
        layers.value[`layer${objective}`].shouldReverse = false
        layers.value[`layer${objective}`].currentId = null
      }, 300)
    }
    const simpleMenuStatus = ref(null)
    const setSimpleMenuStatus = (title) => {
      if (simpleMenuStatus.value === title) {
        simpleMenuStatus.value = null
        return
      }

      simpleMenuStatus.value = title
    }

    const getPreviousName = computed(() => {
      if (layers.value.layer4.status === true) {
        for (const child of main.value) {
          for (const innerChild of child.children) {
            if (innerChild.id === layers.value.layer3.currentId) {
              return innerChild.title
            }
          }
        }
      } else if (layers.value.layer3.status === true) {
        for (const child of main.value) {
          if (child.id === layers.value.layer2.currentId) {
            return child.title
          }
        }
      } else if (layers.value.layer2.status === true) {
        return 'Hoofdmenu'
      }
    })

    return {
      ...setupSearch(),
      ...setupTranslationPrefixer('MobileMenu', domain),
      addNextChildren,
      getPreviousName,
      homeUrl,
      ikZoekBaas,
      layers,
      reverse,
      setSimpleMenuStatus,
      simpleMenu,
      simpleMenuStatus,
      main,
      service,
      secondaryMenuTitle,
      cta,
    }
  },
})
