
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    url: { type: [String, Object], required: false, default: undefined },
    fullWidth: { type: Boolean, required: false, default: false },
    centerContent: { type: Boolean, required: false, default: undefined },
    buttonType: {
      type: String,
      required: true,
      validator: (value) =>
        [
          'primary',
          'primary-flat',
          'secondary',
          'secondary-flat-left',
          'tertiary',
          'quaternary',
          'quinary',
          'filter',
          'lid-primary',
          'lid-secondary',
          'lid-tertiary',
          'lid-read-more',
        ].includes(value),
    },
  },
})
