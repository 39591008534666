import xor from 'lodash/xor'

export const state = () => ({
  // Global results (i.e. unaffected by search context)
  allFacets: {},

  // Search context
  categories: [],
  pageNumber: 1,
  site: 'default',
  siteGroup: 'ddcf22b1-df04-4c71-88cb-3f4d54315708',
  subjects: [],
  types: [],

  // Search results
  articles: [],
  facets: {},
  hits: [],
  totalHits: 0,
})

export const getters = {
  // Constants
  hitsPerPage: () => 18,

  // Search context
  categories: (state) => state.categories,
  pageNumber: (state) => state.pageNumber,
  site: (state) => state.site,
  siteGroup: (state) => state.siteGroup,
  subjects: (state) => state.subjects,
  types: (state) => state.types,

  // Url parameters
  urlParameters: (state) => ({
    categories: state.categories,
    page: state.pageNumber === 1 ? undefined : state.pageNumber,
    subjects: state.subjects,
    types: state.types,
  }),

  // Search results
  facets: (state) => {
    const { allFacets, categories, facets, subjects } = state
    const helper = (fromAllFacets, fromFacets, activeList) => {
      const currentCountMap = Object.fromEntries((fromFacets || []).map(({ count, label, value }) => [value || label, count]))
      return (fromAllFacets || []).map(({ value, label }) => ({
        active: activeList.includes(value || label),
        count: currentCountMap[value || label] || 0,
        label,
        value: value || label,
      }))
    }
    return [
      { name: 'categories', values: helper(allFacets.categories, facets.categories, categories) },
      { name: 'subjects', values: helper(allFacets.subjects, facets.subjects, subjects) },
      // DB-xxx: hide the types facet from the available facets list
      // { name: 'types', values: helper(allFacets.types, facets.types, types) },
    ]
  },
  activeFacets: (state) => {
    const { allFacets, categories, subjects, types } = state
    const helper = (fromAllFacets, activeList, name) =>
      (fromAllFacets || []).filter(({ value, label }) => activeList.includes(value || label)).map(({ value, label }) => ({ name, label, value: value || label }))
    return [...helper(allFacets.subjects, subjects, 'subjects'), ...helper(allFacets.types, types, 'types'), ...helper(allFacets.categories, categories, 'categories')]
  },
  articles: (state) => state.articles,
  hits: (state) => state.hits,
  totalHits: (state) => state.totalHits,
}

export const mutations = {
  // Global results (i.e. unaffected by search context)
  setAllFacets: (state, allFacets) => (state.allFacets = allFacets),

  // Initialize
  initialize: (state, { site, siteGroup, categories, page, subjects, types }) => {
    const toArray = (value) => (value === undefined ? [] : Array.isArray(value) ? value : [value])
    state.site = site
    state.siteGroup = siteGroup
    state.categories = toArray(categories)
    state.pageNumber = typeof page === 'string' ? Math.max(1, Number.parseInt(page)) : 1
    state.subjects = toArray(subjects)
    state.types = toArray(types)
  },

  // Search context
  reset(state) {
    state.categories = []
    state.pageNumber = 1
    state.subjects = []
    state.types = []
    this.dispatch('article-overview/fetch')
  },
  toggleFacet(state, { name, value }) {
    switch (name) {
      case 'categories':
        state.categories = xor(state.categories, [value])
        state.pageNumber = 1
        this.dispatch('article-overview/fetch')
        break
      case 'subjects':
        state.subjects = xor(state.subjects, [value])
        state.pageNumber = 1
        this.dispatch('article-overview/fetch')
        break
      case 'types':
        state.types = xor(state.types, [value])
        state.pageNumber = 1
        this.dispatch('article-overview/fetch')
        break
      default:
        // eslint-disable-next-line no-console
        console.error(`Unable to toggle unknown facet "${name}" with value "${value}"`)
        break
    }
  },
  setPageNumber(state, pageNumber) {
    state.pageNumber = Math.max(1, pageNumber)
    this.dispatch('article-overview/fetch')
  },

  // Search results
  setArticles: (state, articles) => (state.articles = articles),
  setFacets: (state, facets) => (state.facets = facets),
  setHits: (state, hits) => (state.hits = hits),
  setTotalHits: (state, totalHits) => (state.totalHits = totalHits),
}

export const actions = {
  async fetchAllFacets({ commit, dispatch, getters }) {
    const { site, siteGroup } = getters
    const key = `article-overview;all-facets;site:${site};siteGroup:${siteGroup}`
    const ttl = 900

    const fetcher = async () => {
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query allArticleFacets($site:[String], $siteGroup:[String]) {
            elasticSearch(site:$site, searchSiteGroupFacet: $siteGroup, section:"article") {
              facets(limit:1024) {
                categories: articleCategories { value, count }
                subjects: articleSubjects { value, count }
                types: articleType { value, count }
              }
            }
          }`,
        variables: {
          site:
            site === 'inspectiedienst'
              ? [site] // Only look in the subsite
              : ['default', site], // Always look on the corporate site to match Articles marked with the additional searchSiteGroup
          siteGroup,
        },
      })
      return elasticSearch.facets
    }

    // Fetch the hits
    const allFacets = await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    commit('setAllFacets', allFacets)
    return allFacets
  },

  async fetch({ commit, dispatch, getters }) {
    const { categories, hitsPerPage, pageNumber, site, siteGroup, subjects, types } = getters
    const key = [
      'article-overview',
      `site:${site}`,
      `siteGroup:${siteGroup}`,
      `categories:${categories.join(',')}`,
      `subjects:${subjects.join(',')}`,
      `types:${types.join(',')}`,
    ].join(';')
    const ttl = 300

    const fetcher = async () => {
      // Fetch 1024 results, therefore we will not need to fetch new results when we switch pages
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query searchArticles($site:[String], $siteGroup: [String], $categories:[String], $subjects:[String], $types:[String]) {
            elasticSearch(site:$site, searchSiteGroupFacet: $siteGroup, section:"article", articleCategoriesFacet:$categories, articleSubjectsFacet:$subjects, articleTypeFacet:$types, orderBy:"postDate DESC") {
              facets(limit:1024) {
                categories: articleCategories { value, count }
                subjects: articleSubjects { value, count }
                types: articleType { value, count }
              }
              count
              hits(limit:1024) {
               site: siteHandle
               uri
              }
            }
          }`,
        variables: {
          site:
            site === 'inspectiedienst'
              ? [site] // Only look in the subsite
              : ['default', site], // Always look on the corporate site to match Articles marked with the additional searchSiteGroup
          siteGroup,
          categories,
          subjects,
          types,
        },
      })

      return {
        facets: elasticSearch.facets,
        hits: elasticSearch.hits,
        totalHits: elasticSearch.count,
      }
    }

    // Fetch the hits
    const { facets, hits, totalHits } = await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    commit('setFacets', facets)
    commit('setHits', hits)
    commit('setTotalHits', totalHits)

    // Fetch all associated entries within the current page
    const slice = hits.slice((pageNumber - 1) * hitsPerPage, pageNumber * hitsPerPage)
    const articles = await Promise.all(slice.map((hit) => dispatch('page/fetchUri', hit, { root: true })))
    commit(
      'setArticles',
      articles.filter((article) => article)
    )
    return articles
  },
}
