
import { watch, defineComponent, ref, useContext, useRouter } from '@nuxtjs/composition-api'
import { linkDetails } from '~/assets/link'
import { getLinkRewrites } from '~/assets/craft'

const validTargets = ['_self', '_blank', '_parent', '_top']

export default defineComponent({
  props: {
    url: { type: [Object, String], required: false, default: undefined },
    target: { type: String, required: false, default: undefined, validator: (value) => validTargets.includes(value) },
    fallbackComponent: { type: String, required: false, default: 'span' },
  },
  setup(props) {
    const { $config, localePath, req } = useContext()
    const router = useRouter()
    const currentHost = `//${req?.headers.host || window?.location.host}`
    const rewrites = getLinkRewrites($config)

    const fallbackTarget = ref(undefined)
    const href = ref(undefined)
    const rel = ref(undefined)
    const scope = ref(undefined)

    const onClick = (event) => {
      if (href && scope.value === 'inside') {
        router.push(href.value)
        event.preventDefault()
      }
    }

    const setter = (values) => {
      fallbackTarget.value = values.fallbackTarget
      href.value = values.href
      rel.value = values.rel
      scope.value = values.scope
    }

    const update = (target) => {
      if (target && typeof target === 'object') {
        target = localePath(target)
      }

      if (!target) {
        return setter({ href: '' })
      }

      const { distance, scope, url } = linkDetails(currentHost, target, { rewrites })
      switch (scope) {
        // Use NuxtLink because this is inside the app
        case 'inside':
          return setter({ href: url, scope })

        // Fallback to old-school <a href=...>
        case 'outside':
        default:
          return setter(distance === -1 ? { fallbackTarget: '_blank', href: url, rel: 'noopener', scope } : { href: url, scope })
      }
    }

    // Immediately set the link
    update(props.url)

    // Update the link whenever the url changes
    watch(props, () => update(props.url))

    return {
      fallbackTarget,
      href,
      onClick,
      rel,
    }
  },
})
