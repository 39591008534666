//
// Note that this storage is configured as localStorage, see nuxt-vuex-localstorage in nuxt.config.js
//

import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import omitBy from 'lodash/omitBy'

export const state = () => ({ profiles: [] })

export function normalizeProfile(profile) {
  return omitBy(profile, (value) => value === undefined || value === null || value === '')
}
const hasProfile = (state, profile) => {
  const normalizedProfile = normalizeProfile(profile)
  return state.profiles.find((element) => isEqual(normalizeProfile(element), normalizedProfile)) !== undefined
}

export const mutations = {
  reset(state) {
    if (state.profiles.length) state.profiles = []
  },
  setProfiles(state, profiles) {
    state.profiles = profiles
  },
  mergeProfiles(state, profiles) {
    // Only merge when the current profile is empty and the new profile is not empty
    // Otherwise we choose to ignore the merge request, because there is no way we can determine which version is better
    if (state.profiles.length === 0 && profiles?.length > 0) {
      state.profiles = cloneDeep(profiles)
    }
  },
  toggleProfile(state, profile) {
    if (hasProfile(state, profile)) {
      state.profiles = state.profiles.filter((element) => !isEqual(element, profile))
    } else {
      state.profiles = [...state.profiles, cloneDeep(profile)]
    }
  },
}

export const getters = {
  hasProfile: (state) => (profile) => hasProfile(state, profile),
  hasProfiles: (state) => state.profiles.length > 0,
  getProfiles: (state) => state.profiles,
}
