
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import { computed, defineComponent } from '@nuxtjs/composition-api'

const validIcons = [
  'app-cat',
  'app-cat-jumping-far',
  'app-cat-jumping-high',
  'app-cat-jumping-left',
  'app-cat-parading',
  'app-cat-sitting',
  'app-cat-walking',
  'app-cat-washing',
  'app-checkbox-checked',
  'app-checkbox-unchecked',
  'app-dog',
  'app-exclamation-mark',
  'app-facebook',
  'app-facebook-f',
  'app-female',
  'app-female-large',
  'app-filter',
  'app-globe',
  'app-heart',
  'app-heart-filled',
  'app-linked-in',
  'app-loader',
  'app-locate',
  'app-location-pin',
  'app-log-out',
  'app-male',
  'app-male-large',
  'app-unknown-gender',
  'app-parrot',
  'app-pencil',
  'app-pig',
  'app-rabbit',
  'app-rat',
  'app-x-formerly-known-as-twitter',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'birthday-cake',
  'calendar',
  'cart',
  'chain',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'circled-check',
  'gear',
  'hamburger',
  'heart',
  'heart-filled',
  'instagram',
  'mail',
  'minus',
  'paw',
  'pdf-file',
  'person',
  'phone',
  'piggy-bank',
  'plus',
  'search',
  'whats-app',
  'x',
]

export default defineComponent({
  props: {
    icon: { type: String, required: true, default: 'x', validator: (value) => validIcons.includes(value) },
    classes: { type: [String, Array, Object], required: false, default: undefined },
  },
  setup(props) {
    const componentName = computed(() => `AtomIcon${upperFirst(camelCase(props.icon))}`)
    return { componentName }
  },
})
