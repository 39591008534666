import kebabCase from 'lodash/kebabCase'

const hitsPerPage = 15

export const state = () => ({
  // Search context
  pageNumber: 1,

  // Results
  animalSuccesses: [],
  hits: [],
  totalHits: 0,
})

export const getters = {
  // Constants
  hitsPerPage: () => hitsPerPage,

  // Url parameters
  urlParameters: (state) => ({
    page: state.pageNumber === 1 ? undefined : state.pageNumber,
  }),

  // Search context
  pageNumber: (state) => state.pageNumber,

  // Results
  animalSuccesses: (state) => state.animalSuccesses,
  hits: (state) => state.hits,
  totalHits: (state) => state.totalHits,
}

export const mutations = {
  // Initialize
  initialize: (state, { page }) => {
    state.pageNumber = Math.max(1, page || 1)
  },

  // Search context
  setPageNumber(state, pageNumber) {
    state.pageNumber = Math.max(1, pageNumber)
    this.dispatch('animal-success-overview/fetch')
  },

  // Results
  setAnimalSuccesses: (state, animalSuccesses) => (state.animalSuccesses = animalSuccesses),
  setHits: (state, hits) => (state.hits = hits),
  setTotalHits: (state, totalHits) => (state.totalHits = totalHits),
}

export const actions = {
  async fetch({ commit, dispatch, getters }) {
    const { pageNumber } = getters
    const key = `animal-success-overview`
    const ttl = 3600

    const fetcher = async () => {
      // Fetch 1024 results, therefore we will not need to fetch new results when we switch pages
      const { count, submissions } = await this.$graphqlFetch({
        token: 'animal',
        query: `query animalSuccesses {
          count: formieSubmissionCount(form: "animalSuccess")
          submissions: formieSubmissions(form: "animalSuccess", limit: 1024) {
            ... on animalSuccess_Submission {
              id
              nameAnimal
            }
          }
        }
      `,
      })

      return {
        hits: submissions.map(({ id, nameAnimal }) => `succesvol-geplaatst/${id}-${kebabCase(nameAnimal)}`),
        totalHits: count,
      }
    }

    // Fetch the hits
    const { hits, totalHits } = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: { hits: [], totalHits: 0 } }, { root: true })
    commit('setHits', hits)
    commit('setTotalHits', totalHits)

    // Slice all associated entries within the current page
    const slice = hits.slice((pageNumber - 1) * hitsPerPage, pageNumber * hitsPerPage)

    const animalSuccesses = await Promise.all(slice.map((slug) => dispatch('page/fetchUri', { site: 'ikZoekBaas', uri: slug }, { root: true })))
    commit(
      'setAnimalSuccesses',
      animalSuccesses.filter((animalSuccess) => animalSuccess)
    )
  },
}
