import trim from 'lodash/trim'

export const state = () => ({
  cacheKey: undefined,
})

export const getters = {
  page(state, getters, rootState, rootGetters) {
    return rootGetters['cache/get'](state.cacheKey)
  },
}

export const mutations = {
  cacheKey(state, key) {
    state.cacheKey = key
  },
}

export const actions = {
  async fetchUri({ commit, dispatch }, { site, uri, push, options }) {
    const normalizedUri = trim(uri, '/')

    const key = `page;site:${site};uri:${normalizedUri}`
    const ttl = 300

    const fetcher = async () => {
      return await this.$restFetch(normalizedUri ? `/api/site/${site}/uri/${normalizedUri}.json` : `/api/site/${site}/home.json`, { ...options })
    }

    try {
      return await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    } finally {
      if (push) {
        commit('cacheKey', key)
      }
    }
  },

  async fetchHome({ commit, dispatch }, { site, push, options }) {
    const key = `page;site:${site};home`
    const ttl = 300

    const fetcher = async () => {
      return await this.$restFetch(`/api/site/${site}/home.json`, options)
    }

    try {
      return await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    } finally {
      if (push) {
        commit('cacheKey', key)
      }
    }
  },

  async fetchError({ commit, dispatch }, { site, sourceSection, statusCode, push }) {
    const key = `page;site:${site};statusCode:${statusCode};sourceSection:${sourceSection}`
    const ttl = 300

    const fetcher = async () => {
      return await this.$restFetch(`/api/site/${site}/error/${statusCode}-${sourceSection}.json`)
    }

    try {
      return await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    } finally {
      if (push) {
        commit('cacheKey', key)
      }
    }
  },
}
