import { render, staticRenderFns } from "./LidBase.vue?vue&type=template&id=bd231b72&scoped=true"
import script from "./LidBase.vue?vue&type=script&lang=js"
export * from "./LidBase.vue?vue&type=script&lang=js"
import style0 from "./LidBase.vue?vue&type=style&index=0&id=bd231b72&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd231b72",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismControlPanel: require('/home/userapp/components/Organism/ControlPanel.vue').default,OrganismLidMenu: require('/home/userapp/components/Organism/Lid/Menu.vue').default,OrganismLidFooter: require('/home/userapp/components/Organism/Lid/Footer.vue').default,MoleculeLoaderOverlay: require('/home/userapp/components/Molecule/LoaderOverlay.vue').default})
