import { render, staticRenderFns } from "./DesktopMenu.vue?vue&type=template&id=2d7046fa&scoped=true"
import script from "./DesktopMenu.vue?vue&type=script&lang=js"
export * from "./DesktopMenu.vue?vue&type=script&lang=js"
import style0 from "./DesktopMenu.vue?vue&type=style&index=0&id=2d7046fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d7046fa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomBrandLogoDesktop: require('/home/userapp/components/Atom/Brand/BrandLogoDesktop.vue').default,AtomLink: require('/home/userapp/components/Atom/Link/Link.vue').default,AtomIcon: require('/home/userapp/components/Atom/Icon.vue').default,AtomIconArrowDown: require('/home/userapp/components/Atom/Icon/IconArrowDown.vue').default,MoleculeDesktopMenuItem: require('/home/userapp/components/Molecule/DesktopMenuItem.vue').default,AtomButton: require('/home/userapp/components/Atom/Button.vue').default,AtomIconPerson: require('/home/userapp/components/Atom/Icon/IconPerson.vue').default,AtomIconSearch: require('/home/userapp/components/Atom/Icon/IconSearch.vue').default,AtomIconX: require('/home/userapp/components/Atom/Icon/IconX.vue').default})
