export const state = () => ({
  shelter: undefined,
})

export const mutations = {
  set: (state, shelter) => (state.shelter = shelter),
}

export const getters = {
  get: (state) => state.shelter,
}

export const actions = {
  async fetch({ commit, dispatch }, { site, push }) {
    const key = `shelter;site:${site}`
    const ttl = 3600

    const fetcher = async () => {
      return await this.$restFetch(`/api/site/${site}/shelter.json`)
    }

    const shelter = await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })

    if (push) {
      commit('set', shelter)
    }

    return shelter
  },
}
