import { getLandingPageUri } from '~/assets/craft'

const hitsPerPage = 25

export const state = () => {
  return {
    // Search context
    currentPage: 1,
    keyword: '',
    orderBy: 'relevance',

    // Search results
    hits: [],
    totalHits: 0,
  }
}

export const getters = {
  // Constants
  hitsPerPage: () => hitsPerPage,

  // Search context
  currentPage: (state) => state.currentPage,
  keyword: (state) => state.keyword,
  orderBy: (state) => state.orderBy,

  // Url parameters
  urlParameters: (state) => ({
    orderBy: state.orderBy === 'relevance' ? undefined : state.orderBy,
    search: state.keyword || undefined,
    page: state.currentPage === 1 ? undefined : state.currentPage,
  }),

  // Search results
  hits: (state) => state.hits.slice((state.currentPage - 1) * hitsPerPage, state.currentPage * hitsPerPage),
  totalHits: (state) => state.totalHits,
}

export const mutations = {
  // Initialize
  initialize: (state, { orderBy, page, search }) => {
    // When there are no values given, i.e. from the URL, we will simply keep the most recently used search context
    if (orderBy === undefined && page === undefined && search === undefined) {
      return
    }

    state.orderBy = ['relevance', 'postDate'].includes(orderBy) ? orderBy : 'relevance'
    state.keyword = typeof search === 'string' ? search : ''
    state.currentPage = Math.max(1, typeof page === 'string' && !isNaN(Number.parseInt(page)) ? Number.parseInt(page) : 1)
  },

  // Search context
  reset(state) {
    state.orderBy = 'relevance'
    state.keyword = ''
    state.currentPage = 1
    this.dispatch('page-overview/fetch')
  },
  setOrderBy(state, orderBy) {
    state.orderBy = ['relevance', 'postDate'].includes(orderBy) ? orderBy : 'relevance'
    this.dispatch('page-overview/fetch')
  },
  setCurrentPage(state, page) {
    state.currentPage = Math.max(1, typeof page === 'number' && !isNaN(page) ? page : 1)
    this.dispatch('page-overview/fetch')
  },
  setKeyword(state, keyword) {
    state.keyword = typeof keyword === 'string' ? keyword : ''
    state.currentPage = 1
    this.dispatch('page-overview/fetch')
  },

  // Search results
  setHits: (state, hits) => (state.hits = Array.isArray(hits) ? hits : []),
  setTotalHits: (state, totalHits) => (state.totalHits = typeof totalHits === 'number' ? totalHits : 0),
}

export const actions = {
  async fetch({ commit, dispatch, getters }) {
    const { orderBy, keyword } = getters
    const key = `page-overview;keyword:${keyword};orderBy:${orderBy}`
    const ttl = 300

    // Early return when no keywords are supplied
    if (!keyword) {
      commit('setHits', [])
      commit('setTotalHits', 0)
      return
    }

    const fetcher = async () => {
      // Fetch 1024 results, therefore we will not need to fetch new results when we switch pages
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query search($site:[String], $section:[String], $keyword:String, $orderBy:[String]) {
          elasticSearch(site:$site, section:$section, search:$keyword, orderBy:$orderBy, highlight: ["introduction", "description"]) {
            count
            hits(limit:1024) {
             title
             slug
             uri
             sectionHandle
             postDate
             highlights
            }
          }
        }`,
        variables: {
          keyword,
          orderBy: orderBy === 'postDate' ? ['postDate DESC'] : null,
          // don't use section "landing", as it only contains {"slug": "landing", "uri": "__home__"}
          section: ['article', 'articleOverview', 'department', 'jobOffer', 'overview', 'page', 'special', 'home', 'animalHome'],
          site: 'default',
        },
      })

      return {
        // When a 'department' is found we want to redirect it to the associated landing page
        hits: elasticSearch.hits.map((hit) => (hit.sectionHandle === 'department' ? { ...hit, uri: getLandingPageUri(hit.slug) } : hit)),
        totalHits: elasticSearch.count,
      }
    }

    // Fetch the hits
    const { hits, totalHits } = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: { hits: [], totalHits: 0 } }, { root: true })
    commit('setHits', hits)
    commit('setTotalHits', totalHits)
  },
}
