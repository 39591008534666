
import { computed, defineComponent, useStore } from '@nuxtjs/composition-api'
import { setupSiteConfig } from '~/assets/craft'
import { setupTranslationPrefixer } from '~/assets/util'

export default defineComponent({
  setup() {
    const store = useStore()
    const { site } = setupSiteConfig()
    const scrollToTop = () => {
      window.scrollTo(0, 0)
    }

    return {
      ...setupTranslationPrefixer('footer'),
      bottom: computed(() => store.getters['navigation/bottom']),
      footer: computed(() => store.getters['navigation/footer']),
      scrollToTop,
      site,
    }
  },
})
