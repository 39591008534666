const navigationFallback = { bottom: [], footer: [], main: [], service: [], secondaryMenuTitle: undefined, cta: undefined }

export const state = () => ({
  // The current path, used to determine what the active navigation element is
  fullPath: undefined,

  // The id of the current navigation item that is toggled open
  openItemId: undefined,

  // Navigation elements
  navigation: navigationFallback,
})

const annotateNavigationItems = (items, openItemId, fullPath) => {
  return (
    items?.map((item) => {
      const children = annotateNavigationItems(item.children, openItemId, fullPath)
      return {
        ...item,
        isOpen: children.length && (children.some((child) => child.isOpen) || item.id === openItemId),
        isActive: children.some((child) => child.isActive) || (item.url && item.url?.match(/^(https?:\/\/[^/]+)?(?<fullPath>.*)/i)?.groups.fullPath === fullPath),
        children,
      }
    }) || []
  )
}

export const getters = {
  bottom: (state) => annotateNavigationItems(state.navigation.bottom, state.openItemId, state.fullPath),
  footer: (state) => annotateNavigationItems(state.navigation.footer, state.openItemId, state.fullPath),
  main: (state) => annotateNavigationItems(state.navigation.main, state.openItemId, state.fullPath),
  service: (state) => annotateNavigationItems(state.navigation.service, state.openItemId, state.fullPath),

  // Values used in navigation components
  secondaryMenuTitle: (state) => state.navigation.secondaryMenuTitle,
  cta: (state) => state.navigation.cta,
}

export const mutations = {
  closeAll: (state) => (state.openItemId = undefined),
  setNavigation: (state, navigation) => (state.navigation = navigation),
  setRoute: (state, route) => (state.fullPath = route.fullPath),
  toggleOpen: (state, id) => (state.openItemId = state.openItemId === id ? undefined : id),
}

export const actions = {
  async fetch({ commit, dispatch }, { site }) {
    const key = `navigation;${site}`
    const ttl = 3600

    const fetcher = async () => {
      const [bottom, footer, main, service, shelter, cta] = await Promise.all([
        this.$restFetch(`/api/site/${site === 'inspectiedienst' ? 'inspectiedienst' : 'default'}/navigation/bottom.json`),
        this.$restFetch(`/api/site/${site}/navigation/footer.json`),
        this.$restFetch(`/api/site/${site}/navigation/main.json`),
        this.$restFetch(`/api/site/${site}/navigation/service.json`),
        ['default', 'ikZoekBaas', 'mijnDierIsZoek', 'inspectiedienst'].includes(site) ? undefined : this.$restFetch(`/api/site/${site}/shelter.json`),
        this.$restFetch(`/api/global/cta/${site}.json`),
      ])
      return { bottom: bottom.data, footer: footer.data, main: main.data, service: service.data, secondaryMenuTitle: shelter?.title, cta }
    }

    const navigation = await dispatch('cache/fetch', { key, ttl, fetcher, fallback: navigationFallback }, { root: true })
    commit('setNavigation', navigation)
    return navigation
  },
}
