import { render, staticRenderFns } from "./LinkWithIcon.vue?vue&type=template&id=c5c4f222"
import script from "./LinkWithIcon.vue?vue&type=script&lang=js"
export * from "./LinkWithIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomIconArrowRight: require('/home/userapp/components/Atom/Icon/IconArrowRight.vue').default,AtomLink: require('/home/userapp/components/Atom/Link/Link.vue').default})
