function flatten(object, prefix, result) {
  for (const [key, value] of Object.entries(object)) {
    const name = prefix ? `${prefix}.${key}` : key
    if (typeof value === 'object') {
      flatten(value, name, result)
    } else {
      result[name] = value
    }
  }
  return result
}

export const state = () => ({
  locale: 'nl',
  translations: {},
})

export const getters = {
  locale: (state) => state.locale,
  translations: (state) => state.translations,
}

export const mutations = {
  set: (state, { locale, translations }) => {
    state.locale = locale
    state.translations = translations
  },
}

export const actions = {
  async load({ commit, dispatch }, { locale }) {
    // Value "this.$i18n.locale" is not available, as this is called in config of i18n
    const key = `translations;${locale}`
    const ttl = 3600

    const fetcher = async () => {
      // Unable to use "this.$restFetch" here because this plugin is not yet available when i18n calls this store action
      const response = await (await fetch(`${this.$config.cmsSiteUrl}/api/site/default/translations.json`)).json()
      // Merge the static translations from disk with the dynamic translations from the database
      return {
        ...flatten(require(`~/assets/translations/${locale}.json`), '', {}),
        ...Object.fromEntries(response.data.map((entry) => [entry.key, entry.value])),
      }
    }

    const translations = await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    commit('set', { locale, translations })
    return translations
  },
}
