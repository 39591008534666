export const state = () => ({})

export const getters = {}

export const mutations = {}

export const actions = {
  async fetchAnimalCounts({ dispatch }, { site, animalType }) {
    const key = `animal-facets;site:${site};animalType:${animalType}`
    const ttl = 3

    const fetcher = async () => {
      const { elasticSearch } = await this.$graphqlFetch({
        query: `query fetchAnimalCategories($site: [String], $animalType: [String]) {
          elasticSearch(section: "animal", site: $site, animalTypeFacet: $animalType) {
            facets {
              animalCategory { 
                value
                count
              }
            }
          }
        }`,
        variables: {
          site,
          animalType,
        },
        params: { animalCategory: animalType },
        token: 'elasticsearch',
      })

      return Object.fromEntries(elasticSearch.facets.animalCategory.map(({ value, count }) => [value, count]))
    }

    return await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
  },

  async fetch({ commit, dispatch }, { id, push, options }) {
    const key = `animal;id:${id}`
    const ttl = 300

    const fetcher = async () => {
      return await this.$restFetch(`/api/animal/${id}.json`, { ...options })
    }

    try {
      return await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    } finally {
      if (push) {
        commit('page/cacheKey', key, { root: true })
      }
    }
  },
}
