
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    preTitle: { type: String, required: false, default: undefined },
    title: { type: String, required: false, default: undefined },
    subtitle: { type: String, required: false, default: undefined },
    introduction: { type: String, required: false, default: undefined },
    image: { type: Object, required: false, default: undefined },
    positionTextLeft: { type: Boolean, required: false, default: false },
    darkTitle: { type: Boolean, required: false, default: false },
    shortImage: { type: Boolean, required: false, default: false },
  },
})
