//
// This plugin detects when something user related changes and ensures that client-side and server-side user
// data is kept in sync.
//

import { onGlobalSetup, defineNuxtPlugin, watch } from '@nuxtjs/composition-api'
import { setupUser } from '~/assets/user'

export default defineNuxtPlugin(({ store, $graphqlFetch }) => {
  const updateServerSideSearchProfiles = async (checkToken, searchProfiles) => {
    if (!(await checkToken())) {
      return false
    }

    if (!Array.isArray(searchProfiles)) {
      return false
    }

    try {
      await $graphqlFetch({
        headers: { Authorization: `JWT ${store.getters['jwt/token']}` },
        query: `
          mutation updateViewer($searchProfiles: String) {
            user: updateViewer(userSearchProfiles: $searchProfiles) {
              uid
            }
          }
        `,
        variables: {
          searchProfiles: JSON.stringify(searchProfiles),
        },
      })
    } catch {
      return false
    }
    return true
  }

  const updateServerSideWishList = async (checkToken, wishList) => {
    if (!(await checkToken())) {
      return false
    }

    if (!Array.isArray(wishList)) {
      return false
    }

    try {
      await $graphqlFetch({
        headers: { Authorization: `JWT ${store.getters['jwt/token']}` },
        query: `
          mutation updateViewer($wishList: String) {
            updateViewer(userWishList: $wishList) {
              uid
            }
          }
        `,
        variables: {
          wishList: JSON.stringify(wishList),
        },
      })
    } catch {
      return false
    }
    return true
  }

  onGlobalSetup(() => {
    const { checkToken } = setupUser()

    // Update the server-side data whenever the store changes
    watch(store.state.searchProfiles, ({ profiles }) => updateServerSideSearchProfiles(checkToken, profiles))
    watch(store.state.wishList, ({ profile }) => updateServerSideWishList(checkToken, profile))
  })
})
