
import { computed, defineComponent, ref, useContext, useRoute, useRouter, useStore } from '@nuxtjs/composition-api'
import { getHomeUrl, setupSiteConfig } from '~/assets/craft'
import { setupTranslationPrefixer } from '~/assets/util'

export default defineComponent({
  setup() {
    const store = useStore()
    const { $autoHeightFlow, localePath } = useContext()
    const { domain } = setupSiteConfig()
    const route = useRoute()
    const router = useRouter()
    const menuTracker = ref('')
    const currentMenu = ref('')
    const { url: homeUrl, simpleMenu, ikZoekBaas } = getHomeUrl(route.value)

    const currentMenuId = ref(null)
    const desktopSearchStatus = ref(false)
    const desktopSearchInput = ref('')
    const desktopSearch = () => {
      desktopSearchStatus.value = !desktopSearchStatus.value
    }
    const emitSearch = () => {
      desktopSearchStatus.value = false
      router.push(localePath({ path: '/zoeken', query: { search: desktopSearchInput.value } }))
    }

    const addNextChildren = (item) => {
      if (item.children.length) {
        if (menuTracker.value === item.title) {
          menuTracker.value = ''
        } else {
          menuTracker.value = item.title
        }

        toggle(item)
      }

      if (currentMenuId.value === item.id) {
        currentMenuId.value = null
      } else {
        currentMenuId.value = item.id
        // The next chunk of code is to better navigate through the main menu with the keyboard
        const parentElement = document.getElementById(`button--${currentMenuId.value}`)
        const itemLinks = document.getElementById(`children--${currentMenuId.value}`)?.querySelectorAll('a') || []
        if (parentElement && itemLinks.length > 0) {
          setTimeout(() => {
            itemLinks[0].focus()
            document.addEventListener('keydown', (event) => {
              if (event.shiftKey && event.key === 'Tab') {
                const focusedElement = document.activeElement
                if (focusedElement === itemLinks[0]) {
                  event.preventDefault()
                  parentElement.focus()
                }
              }
            })
          }, 0) // A timeout of 0 milliseconds is used so the function will get queued and called when the browser is ready.
        }
      }
    }

    const toggle = (menu) => {
      const dom = document.querySelector('.js-auto-height-flow-menu')

      if (currentMenu.value.title !== menu.title && dom.style.maxHeight !== 0 && dom.style.maxHeight !== '' && dom.style.maxHeight !== '0px') {
        $autoHeightFlow(dom)
        currentMenu.value = menu
        $autoHeightFlow(dom)
        return
      }

      currentMenu.value = menu
      $autoHeightFlow(dom)
    }

    return {
      ...setupTranslationPrefixer('DesktopMenu', domain),
      addNextChildren,
      currentMenuId,
      desktopSearch,
      desktopSearchInput,
      desktopSearchStatus,
      emitSearch,
      homeUrl,
      ikZoekBaas,
      menuTracker,
      simpleMenu,
      domain,
      toggle,
      main: computed(() => store.getters['navigation/main']),
      service: computed(() => store.getters['navigation/service']),
      secondaryMenuTitle: computed(() => store.getters['navigation/secondaryMenuTitle']),
      cta: computed(() => store.getters['navigation/cta']),
    }
  },
})
