
import { defineComponent, useStore, watch } from '@nuxtjs/composition-api'
import { useCookiebot } from '~/assets/composition/cookiebot'

function setupBodyOverflow() {
  const store = useStore()

  // Add `overflow-hidden` to the body whenever one of the overlays is open
  if (process.client) {
    watch(store.state.overlay, ({ loader, mobileMenu }) => {
      if (loader || mobileMenu) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    })
  }

  // Reset the overlay every time we load a new page
  store.commit('overlay/reset')

  return {}
}

export default defineComponent({
  setup() {
    useCookiebot()

    return {
      ...setupBodyOverflow(),
    }
  },
  head: {
    link: [
      // Favicon
      { rel: 'shortcut icon', type: 'image/x-icon', href: '/favicons/LID/favicon-lid.ico' },

      // Apple Favicons
      { rel: 'apple-touch-icon', sizes: '180x180', href: '/favicons/LID/apple-touch-icon.png' },
      { rel: 'mask-icon', color: '#f2b844', href: '/favicons/LID/safari-pinned-tab.svg' },

      // Favicon general
      { rel: 'icon', sizes: '32x32', href: '/favicons/LID/favicon-32x32.png' },
      { rel: 'icon', sizes: '16x16', href: '/favicons/LID/favicon-16x16.png' },
      { rel: 'manifest', href: '/favicons/LID/site.webmanifest' },

      // Favicon Microsoft
      { name: 'msapplication-TileColor', content: '#f2b844' },
      { name: 'theme-color', content: '#ffffff' },
    ],
  },
})
