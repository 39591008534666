export const fallbackDistance = '1000km'
export const distances = ['10km', '25km', '50km', fallbackDistance]

export const actions = {
  async resolve({ commit, dispatch, getters }, { location: inputLocation, distance: inputDistance }) {
    const location = inputLocation || ''
    const distance = inputDistance || fallbackDistance
    const key = `location-search;location:${location};distance:${distance}`
    const ttl = 3600

    const fetcher = async () => {
      // todo: move the auth_key to an environment
      // Note that the auth_key is publicly available.  We may want to move this into the bridge at some point
      let url
      let type
      let fallbackErrorMessage
      if (/^[0-9]{4}\s*[a-z]{2}$/i.test(location)) {
        // The input resembles a nl_sixpp zip code
        url = `https://api.pro6pp.nl/v1/autocomplete?auth_key=yxXpIlblhsWuVQDJ&nl_sixpp=${location}`
        type = 'nl_sixpp'
        fallbackErrorMessage = 'unspecified-nl-sixpp'
      } else if (/^[a-z]{2}/i.test(location)) {
        // The input resembles a city name
        url = `https://api.pro6pp.nl/v1/suggest?auth_key=yxXpIlblhsWuVQDJ&per_page=1&nl_city=${location}`
        type = 'city'
        fallbackErrorMessage = 'unspecified-nl-city'
      } else {
        return { status: 'none', location, distance }
      }

      const result = await fetch(url).then((result) => result.json())
      if (result?.status === 'ok' && result?.results?.[0]?.lng && result?.results?.[0]?.lat) {
        return { status: 'ok', location, distance, city: result.results[0].city, lat: result.results[0].lat, lng: result.results[0].lng, type, errors: [] }
      }

      return { status: 'error', location, distance, errors: [result?.error?.message || fallbackErrorMessage] }
    }

    return await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
  },
}
