import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin((context, inject) => {
  // bridge requests: POST, PUT requests must also be allowed
  inject('bridgeFetch', async (uri, init = {}) => {
    const url = [context.$config.bridgeSiteUrl, uri].join('')

    let response
    let payload
    try {
      response = await fetch(url, init)
      if (response.status !== 200) {
        throw new Error(`Unable to fetch ${url}`)
      }
      payload = await response.json()
      return payload
    } finally {
      if (context.isDev) {
        /* eslint-disable no-console */
        if (process.client) {
          console.groupCollapsed(url)
          console.info(response)
          console.info(payload)
          console.groupEnd()
        } else {
          console.info(url)
        }
      }
      /* eslint-enable no-console */
    }
  })

  // REST endpoint
  inject('restFetch', async (uri, options = {}) => {
    const preview = context.query['x-craft-preview'] || context.query['x-craft-live-preview'] || undefined
    const url = [context.$config.cmsSiteUrl, uri, preview && context.query.token ? `?preview=${preview}&token=${context.query.token}` : ''].join('')

    let response
    let payload
    try {
      response = await fetch(url, options)
      if (response.status !== 200) {
        throw new Error(`Unable to fetch ${url}`)
      }
      payload = await response.json()
      return payload
    } finally {
      if (context.isDev) {
        /* eslint-disable no-console */
        if (process.client) {
          console.groupCollapsed(url)
          console.info(response)
          console.info(payload)
          console.groupEnd()
        } else {
          console.info(url)
        }
        /* eslint-enable no-console */
      }
    }
  })

  // GraphQL endpoint
  inject('graphqlFetch', async ({ query, variables, token, headers, params }) => {
    const tokenMap = {
      animal: context.$config.animalGraphqlPublicToken,
      elasticsearch: context.$config.elasticsearchGraphqlPublicToken,
      formie: context.$config.formieGraphqlPublicToken,
      user: context.$config.userGraphqlPublicToken,
    }

    params = params instanceof URLSearchParams ? params : params instanceof Object ? new URLSearchParams({ ...params }) : null

    let response
    let payload
    try {
      response = await fetch(`${context.$config.cmsSiteUrl}/api${params && params.size > 0 ? `?${params}` : ''}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...(tokenMap[token] ? { Authorization: `Bearer ${tokenMap[token]}` } : {}),
          ...(headers || {}),
        },
        body: JSON.stringify({ query, variables }),
      })

      if (response.status !== 200) {
        throw new Error(`GraphQL error: invalid status ${response.status}`)
      }

      payload = await response.json()
      if (payload?.errors?.length) {
        throw new Error(payload?.errors?.[0]?.message || 'Unspecified GraphQL error')
      }

      return payload?.data
    } finally {
      if (context.isDev) {
        /* eslint-disable no-console */
        if (process.client || response.status !== 200 || payload?.errors?.length) {
          console.groupCollapsed('graphqlFetch')
          console.info(`token: ${token}`)
          console.info(query)
          console.info(variables)
          console.info(response)
          console.info(payload)
          console.groupEnd()
        } else {
          console.info('graphqlFetch')
        }
        /* eslint-enable no-console */
      }
    }
  })
})
