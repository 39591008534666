//
// Note that this storage is configured as localStorage, see nuxt-vuex-localstorage in nuxt.config.js
//

import pull from 'lodash/pull'
import xor from 'lodash/xor'
import { set } from 'vue'

export const state = () => ({ profile: [] })

function normalizeAnimalId(animalId) {
  return typeof animalId === 'string' ? Number.parseInt(animalId) : animalId
}

export const mutations = {
  reset(state) {
    if (state.profile.length) {
      set(state, 'profile', [])
    }
  },
  setFavorites(state, animalIds) {
    set(
      state,
      'profile',
      animalIds.map((animalId) => normalizeAnimalId(animalId))
    )
  },
  mergeFavorites(state, profile) {
    // Only merge when the current profile is empty and the new profile is not empty
    // Otherwise we choose to ignore the merge request, because there is no way we can determine which version is better
    if (state.profile.length === 0 && profile?.length > 0) {
      set(
        state,
        'profile',
        profile.map((animalId) => normalizeAnimalId(animalId))
      )
    }
  },
  toggleFavorite(state, animalId) {
    set(state, 'profile', xor(state.profile, [normalizeAnimalId(animalId)]))
  },
  removeFavorite(state, animalId) {
    const newProfile = pull(state.profile, normalizeAnimalId(animalId))
    // Yes... the two lines below are the same, but without the second `set` the values do not update reactively
    set(state, 'profile', newProfile)
    set(state, 'profile', newProfile)
  },
}

export const getters = {
  isFavorite: (state) => (animalId) => state.profile.includes(normalizeAnimalId(animalId)),
  hasFavorites: (state) => state.profile.length > 0,
  getFavorites: (state) => state.profile || [],
}
