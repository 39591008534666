export const state = () => {
  return {
    site: 'mijnDierIsZoek',
    animalCounts: {},
  }
}

export const getters = {
  animalCounts: (state) => state.animalCounts,
  site: (state) => state.site,
}

export const mutations = {
  // Initialize
  initialize: (state, { site }) => (state.site = site),

  // Results
  setAnimalCounts: (state, animalCounts) => (state.animalCounts = animalCounts),
}

export const actions = {
  async fetch({ commit, dispatch, getters }) {
    const { site } = getters
    const key = `variant-home-detail;animal-counts;site:${site}`
    const ttl = 900

    const fetcher = async () => {
      const { elasticSearch } = await this.$graphqlFetch({
        token: 'elasticsearch',
        query: `query variantHomeDetail($animalType:[String]) {
          elasticSearch(section: "animal", site: "*", animalTypeFacet: $animalType) {
            facets { 
              animalCategory { value count }
            }
          }
        }`,
        variables: {
          animalType: site === 'mijnDierIsZoek' ? 'found' : 'available',
        },
      })

      return Object.fromEntries(elasticSearch.facets.animalCategory.map((facet) => [facet.value, facet.count]))
    }

    commit('setAnimalCounts', await dispatch('cache/fetch', { key, ttl, fetcher, fallback: {} }, { root: true }))
  },
}
