
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    return {
      props,
    }
  },
})
