import trimEnd from 'lodash/trimEnd'

export const state = () => ({
  cacheKey: undefined,
})

export const getters = {
  get(state, getters, rootState, rootGetters) {
    return rootGetters['cache/get'](state.cacheKey)
  },
}

export const mutations = {
  cacheKey(state, key) {
    state.cacheKey = key
  },
}

export const actions = {
  async fetch({ commit, dispatch }, { site, path }) {
    const normalizedPath = trimEnd(path, '/')
    const key = `redirects;site:${site};uri:${normalizedPath}`
    const ttl = 3600

    const fetcher = async () => {
      return await this.$restFetch(`/api/seo-helper/${site}/find-redirects.json?path=${encodeURI(normalizedPath)}`)
    }

    try {
      return await dispatch('cache/fetch', { key, ttl, fetcher }, { root: true })
    } finally {
      commit('cacheKey', key)
    }
  },
}
