import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _66a7c708 = () => interopDefault(import('../pages/home-detail.vue' /* webpackChunkName: "pages/home-detail" */))
const _3a3a3df4 = () => interopDefault(import('../pages/account/detail.vue' /* webpackChunkName: "pages/account/detail" */))
const _25dafb54 = () => interopDefault(import('../pages/app-health-check.vue' /* webpackChunkName: "pages/app-health-check" */))
const _47ee5744 = () => interopDefault(import('../pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _6f4e4b18 = () => interopDefault(import('../pages/account/register.vue' /* webpackChunkName: "pages/account/register" */))
const _b3d394dc = () => interopDefault(import('../pages/account/resend-activation-email.vue' /* webpackChunkName: "pages/account/resend-activation-email" */))
const _27c034bd = () => interopDefault(import('../pages/account/verify-email.vue' /* webpackChunkName: "pages/account/verify-email" */))
const _3c76bd5c = () => interopDefault(import('../pages/account/password-reset.vue' /* webpackChunkName: "pages/account/password-reset" */))
const _70fcce13 = () => interopDefault(import('../pages/debug/index.vue' /* webpackChunkName: "pages/debug/index" */))
const _c236de6e = () => interopDefault(import('../pages/debug/link.vue' /* webpackChunkName: "pages/debug/link" */))
const _5577181b = () => interopDefault(import('../pages/debug/seo-redirect.vue' /* webpackChunkName: "pages/debug/seo-redirect" */))
const _07859d6f = () => interopDefault(import('../pages/redirect/admin.vue' /* webpackChunkName: "pages/redirect/admin" */))
const _67aa3966 = () => interopDefault(import('../pages/redirect/account.vue' /* webpackChunkName: "pages/redirect/account" */))
const _ff3c3468 = () => interopDefault(import('../pages/shelter-detail.vue' /* webpackChunkName: "pages/shelter-detail" */))
const _ba6fcc02 = () => interopDefault(import('../pages/animal-detail.vue' /* webpackChunkName: "pages/animal-detail" */))
const _6476955a = () => interopDefault(import('../pages/landing-detail.vue' /* webpackChunkName: "pages/landing-detail" */))
const _d49ecb6e = () => interopDefault(import('../pages/redirect/shelter-detail.vue' /* webpackChunkName: "pages/redirect/shelter-detail" */))
const _667b3c3c = () => interopDefault(import('../pages/redirect/animal-detail.vue' /* webpackChunkName: "pages/redirect/animal-detail" */))
const _e87adbc8 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/www",
    component: _66a7c708,
    name: "home-detail___nl"
  }, {
    path: "/ikzoekbaas/account",
    component: _3a3a3df4,
    name: "account-detail___nl"
  }, {
    path: "/www/app-health-check",
    component: _25dafb54,
    name: "app-health-check___nl"
  }, {
    path: "/ikzoekbaas/account/inloggen",
    component: _47ee5744,
    name: "account-login___nl"
  }, {
    path: "/ikzoekbaas/account/registreren",
    component: _6f4e4b18,
    name: "account-register___nl"
  }, {
    path: "/ikzoekbaas/account/resend-activation-email",
    component: _b3d394dc,
    name: "account-resend-activation-email___nl"
  }, {
    path: "/ikzoekbaas/account/verify-email",
    component: _27c034bd,
    name: "account-verify-email___nl"
  }, {
    path: "/ikzoekbaas/account/wachtwoord-reset",
    component: _3c76bd5c,
    name: "account-password-reset___nl"
  }, {
    path: "/www/debug\\/ikzoekbaas/debug\\/mijndieriszoek/debug\\/inspectiedienst/debug",
    component: _70fcce13,
    name: "debug___nl"
  }, {
    path: "/www/debug/link\\/ikzoekbaas/debug/link\\/mijndieriszoek/debug/link\\/inspectiedienst/debug/link",
    component: _c236de6e,
    name: "debug-link___nl"
  }, {
    path: "/www/debug/seo-redirect\\/ikzoekbaas/debug/seo-redirect\\/mijndieriszoek/debug/seo-redirect\\/inspectiedienst/debug/seo-redirect",
    component: _5577181b,
    name: "debug-seo-redirect___nl"
  }, {
    path: "/www/admin/(.*)?\\/ikzoekbaas/admin/(.*)?\\/mijndieriszoek/admin/(.*)?\\/inspectiedienst/admin/(.*)?",
    component: _07859d6f,
    name: "redirect-admin___nl"
  }, {
    path: "/www/account/(.*)?\\/mijndieriszoek/account/(.*)?",
    component: _67aa3966,
    name: "redirect-account___nl"
  }, {
    path: "/ikzoekbaas/afdelingen/:departmentTypeSlug/:siteSlug\\/mijndieriszoek/afdelingen/:departmentTypeSlug/:siteSlug",
    component: _ff3c3468,
    name: "shelter-detail___nl"
  }, {
    path: "/ikzoekbaas/asieldier/:categorySlug/:id-:name\\/mijndieriszoek/:mdizCategory(gevonden-dieren|vermiste-dieren)/:categorySlug/:id-:name",
    component: _ba6fcc02,
    name: "animal-detail___nl"
  }, {
    path: "/www/:departmentTypeSlug(dierenambulance|dierenasiel|hondenschool|wildopvang)/:siteSlug",
    component: _6476955a,
    name: "landing-detail___nl"
  }, {
    path: "/www/:departmentTypeSlug(dierenambulance|dierenasiel|extern|hondenschool|wildopvang)/:siteSlug/detail-redirect",
    component: _d49ecb6e,
    name: "redirect-shelter-detail___nl"
  }, {
    path: "/www/:departmentTypeSlug(dierenambulance|dierenasiel|extern|hondenschool|wildopvang)/:siteSlug/animal-redirect/:animalType/:category/:idAndSlug",
    component: _667b3c3c,
    name: "redirect-animal-detail___nl"
  }, {
    path: "/www/:departmentTypeSlug(dierenambulance|dierenasiel|hondenschool|wildopvang)/:siteSlug/*\\/www/*\\/ikzoekbaas/*\\/mijndieriszoek/*\\/inspectiedienst\\/inspectiedienst/*",
    component: _e87adbc8,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
