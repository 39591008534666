
import { defineComponent, computed } from '@nuxtjs/composition-api'
import { getMediaImage } from '~/assets/media'

export default defineComponent({
  props: {
    image: { type: Object, required: false, default: undefined },
    alt: { type: String, required: false, default: undefined },
    imgClass: { type: String, required: false, default: '' },
    lazy: { type: Boolean, required: false, default: true },
    fallback: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const media = computed(() => getMediaImage(props.image))
    const resolvedAlt = computed(() => props.alt || props.image?.alt || props.image?.title)

    return {
      media,
      resolvedAlt,
      title: props.image?.title,
    }
  },
})
