import kebabCase from 'lodash/kebabCase'
import _truncate from 'lodash/truncate'
import { useContext } from '@nuxtjs/composition-api'

export function stripTags(string) {
  // note: when we move to @zicht/nuxt-util, we will need to add support for non-strings for safety
  return (string || '').replace(/(<([^>]+)>)/gi, '')
}

export function getTranslationPrefixer(i18n) {
  return (componentName, groupName = 'default') => {
    const componentName_ = kebabCase(componentName)
    const groupName_ = kebabCase(groupName)
    return {
      translationComponent_: componentName_,
      translationGroup_: groupName_,

      // Helper functions that prefix and normalize the translation key
      k_: (key) => key && [componentName_, groupName_, kebabCase(key)].join('.'),
      t_: (key, ...args) => key && i18n.t([componentName_, groupName_, kebabCase(key)].join('.'), ...args),
      tc_: (key, ...args) => key && i18n.tc([componentName_, groupName_, kebabCase(key)].join('.'), ...args),
      te_: (key, ...args) => key && i18n.te([componentName_, groupName_, kebabCase(key)].join('.'), ...args),

      // Helper function that automatically converts strings into Date objects
      d_: (value, pattern) => {
        if (!value) {
          return ''
        }
        const date = typeof value === 'object' ? value : new Date(value)
        return pattern === 'iso' ? date.toISOString() : i18n?.d(date, pattern)
      },
    }
  }
}

export function setupTranslationPrefixer(componentName, groupName = 'default') {
  const { i18n } = useContext()
  return getTranslationPrefixer(i18n)(componentName, groupName)
}

/**
 * Truncate a string to `options.length` characters, separated by word boundaries
 *
 * @see https://lodash.com/docs/4.17.15#truncate
 */
export function truncate(string, options) {
  // Separate on word boundaries
  const separator = options?.separator || /[.,!? ]/
  // Use the ellipsis character instead of three dots
  const omission = options?.omission || '&hellip;'
  // Add 6 to the length because '&hellip;' counts as 1 length, not 8.
  const lengthModifierMap = { '&hellip;': 6 }
  const length = Math.max(0, (options?.length || 30) + (lengthModifierMap[omission] || omission?.length || 0))

  return _truncate(string, { length, omission, separator })
}

export function toStringArray(value) {
  if (typeof value === 'string') {
    return [value]
  }
  if (Array.isArray(value) && value.every((val) => typeof val === 'string')) {
    return value
  }

  return []
}

export function toNumber(value, fallback = 0) {
  if (typeof value === 'string') {
    return Number.parseInt(value)
  }

  if (typeof value === 'number') {
    return value
  }

  return fallback
}

/**
 * Returns difference in years or days (not cumulative) based on input dates (as string of Date objects)
 */
export function getDateDifference(a, b) {
  // Ensure both a and b are Date objects
  if (!(a instanceof Date)) a = a ? new Date(a) : new Date()
  if (!(b instanceof Date)) b = b ? new Date(b) : new Date()
  return {
    days: Math.floor(Math.abs((a.getTime() - b.getTime()) / (1000 * 60 * 60 * 24))),
    years: Math.abs(a.getFullYear() - b.getFullYear()),
  }
}

/**
 * Returns the segments from any given URL to determine the protocol, host and parameters
 * @param url
 * @returns {*}
 */
export function getUrlSegments(url) {
  return url.match(/^(?<protocol>[^:]+:)?(\/\/(?<host>[^/]+))?(?<rest>.*$)/i).groups
}

// From: nuxt/node_modules/@nuxt/utils/dist/utils.js
const DYNAMIC_ROUTE_REGEX = /^\/([:*])/
// From: nuxt/node_modules/@nuxt/utils/dist/utils.js
export function sortRoutes(routes) {
  routes.sort((a, b) => {
    if (!a.path.length) {
      return -1
    }
    if (!b.path.length) {
      return 1
    }
    // Order: /static, /index, /:dynamic
    // Match exact route before index: /login before /index/_slug
    if (a.path === '/') {
      return DYNAMIC_ROUTE_REGEX.test(b.path) ? -1 : 1
    }
    if (b.path === '/') {
      return DYNAMIC_ROUTE_REGEX.test(a.path) ? 1 : -1
    }

    let i
    let res = 0
    let y = 0
    let z = 0
    const _a = a.path.split('/')
    const _b = b.path.split('/')
    for (i = 0; i < _a.length; i++) {
      if (res !== 0) {
        break
      }
      y = _a[i] === '*' ? 2 : _a[i].includes(':') ? 1 : 0
      z = _b[i] === '*' ? 2 : _b[i].includes(':') ? 1 : 0
      res = y - z
      // If a.length >= b.length
      if (i === _b.length - 1 && res === 0) {
        // unless * found sort by level, then alphabetically
        res = _a[i] === '*' ? -1 : _a.length === _b.length ? a.path.localeCompare(b.path) : _a.length - _b.length
      }
    }

    if (res === 0) {
      // unless * found sort by level, then alphabetically
      res = _a[i - 1] === '*' && _b[i] ? 1 : _a.length === _b.length ? a.path.localeCompare(b.path) : _a.length - _b.length
    }
    return res
  })

  routes.forEach((route) => {
    if (route.children) {
      sortRoutes(route.children)
    }
  })

  return routes
}
