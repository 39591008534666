import { render, staticRenderFns } from "./LidError.vue?vue&type=template&id=13b2e247"
import script from "./LidError.vue?vue&type=script&lang=js"
export * from "./LidError.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculeLidHeaderOverview: require('/home/userapp/components/Molecule/Lid/HeaderOverview.vue').default,TemplateLidBase: require('/home/userapp/components/Template/Lid/LidBase.vue').default})
